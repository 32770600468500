<template>
  <div>
    <div class="buttons-container">
      <router-link :to="{ name: 'MonitoringPatients' }">
        <Button title="Tableaux" type="primary" />
      </router-link>
      <router-link :to="{ name: 'MonitoringPatientsCharts' }">
        <Button title="Graphs" type="primary" :active="true" />
      </router-link>
    </div>

    <div class="charts-container">
      <div class="patients-list">

        <p class="title">Vue</p>

        <div class="buttons-container-view">
          <Button
            title="3 derniers mois"
            type="primary"
            size="small"
            :active="viewRange === 'last3months'"
            @click="viewRange = 'last3months'"
          />
          <Button
            title="Depuis le début"
            type="primary"
            size="small"
            :active="viewRange === 'fromStart'"
            @click="viewRange = 'fromStart'"
          />
        </div>

        <div class="patient-search">
          <Input
            title="Rechercher"
            name="search"
            v-model="search"
          />
        </div>

        <div class="buttons-container">
          <Button
            title="Tous"
            type="primary"
            size="small"
            :active="selectedPatients.length === filteredPatients.length"
            @click="selectAllPatients"
          />
          <Button
            title="Aucun"
            type="primary"
            size="small"
            :active="selectedPatients.length === 0"
            @click="unSelectAllPatients"
          />
        </div>

        <p class="title">Patients</p>

        <div v-for="patient in filteredPatients" :key="patient.patientId"
          class="patient"
          :class="{ selected: selectedPatientsIds.includes(patient.patientId) }"
          :data-color="patient.color"
          @click="togglePatient(patient)"
        >
          <div class="dot" :style="{ background: selectedPatientsIds.includes(patient.patientId) ? patient.color : '#8E8E95' }"></div>
          {{ patient.firstName }} {{ patient.lastName }}

          <transition name="fade">
            <div class="loading" v-if="selectedPatientsIds.includes(patient.patientId) && !patient.computedStats.usageLast90days">
              <SVGSpinner />
            </div>
          </transition>
        </div>
      </div>

      <transition name="fade" mode="out-in">
        <div class="charts" v-if="selectedPatients.length > 0" key="charts">
          <div class="chart">
            <PatientsUsageDaysChart
              :patients="selectedPatients"
            />
          </div>
          <div class="chart">
            <PatientsUsageChart
              :patients="selectedPatients"
            />
          </div>
          <div class="chart">
            <PatientsSelfWorkoutChart
              :patients="selectedPatients"
            />
          </div>
          <div class="chart">
            <PatientsPerformanceChart
              :patients="selectedPatients"
            />
          </div>
        </div>
        <div class="charts empty" v-else key="charts-empty">
          <p>Sélectionner un ou plusieurs patients pour visualiser leurs statistiques</p>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import { mapGetters } from 'vuex'
import Button from '@/components/Button'
import Input from '@/components/Forms/Fields/Input'
import PatientsUsageDaysChart from '@/components/Charts/PatientsUsageDaysChart'
import PatientsUsageChart from '@/components/Charts/PatientsUsageChart'
import PatientsSelfWorkoutChart from '@/components/Charts/PatientsSelfWorkoutChart'
import PatientsPerformanceChart from '@/components/Charts/PatientsPerformanceChart'
import SVGSpinner from '@/assets/icons/spinner-tail.svg'

export default {
  name: 'MonitoringPatientsCharts',
  components: {
    Button, Input, PatientsUsageDaysChart, PatientsUsageChart, PatientsSelfWorkoutChart, PatientsPerformanceChart, SVGSpinner
  },
  data () {
    return {
      viewRange: 'last3months',
      search: ''
    }
  },
  async created () {
    await this.$store.dispatch('admin/getPatients')
  },
  computed: {
    ...mapGetters({
      patientsList: 'admin/patients',
      selectedPatientsIds: 'admin/selectedPatients'
    }),
    sortedPatients () {
      return _.sortBy(this.patientsList, ['firstName', 'lastName'])
    },
    filteredPatients () {
      return this.sortedPatients.filter(patient => patient.firstName.toLowerCase().includes(this.search.toLowerCase()) || patient.lastName.toLowerCase().includes(this.search.toLowerCase()))
    },
    selectedPatients () {
      return this.sortedPatients.filter(patient => this.selectedPatientsIds.includes(patient.patientId))
    }
  },
  methods: {
    togglePatient (patient) {
      if(this.selectedPatientsIds.includes(patient.patientId)) {
        this.$store.dispatch('admin/unselectPatient', patient.patientId)
      } else {
        this.$store.dispatch('admin/selectPatient', patient.patientId)
        this.$store.dispatch('admin/getPatientStats', { patientId: patient.patientId, days: 91 })
      }
    },
    selectAllPatients () {
      this.sortedPatients.forEach(patient => {
        this.$store.dispatch('admin/selectPatient', patient.patientId)
        this.$store.dispatch('admin/getPatientStats', { patientId: patient.patientId, days: 91 })
      })
    },
    unSelectAllPatients () {
      this.sortedPatients.forEach(patient => {
        this.$store.dispatch('admin/unselectPatient', patient.patientId)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.buttons-container{
  margin-top: 24px;
  display: flex;

  a{
    margin: 0 6px;

    &:first-child{
      margin-left: 0;
    }
    &:last-child{
      margin-right: 0;
    }
  }
}

.charts-container{
  display: flex;
  align-items: flex-start;
  margin-top: 24px;

  .patients-list{
    position: sticky;
    top: 0;
    width: 15%;
    border-radius: 10px;
    border: 1px solid #F0F0F0;
    padding: 0 0 15px 0;

    .buttons-container-view{
      margin-top: 0;
      flex-direction: column;

      .button{
        margin-bottom: 10px;
      }
    }

    .buttons-container{
      margin-top: 0;
      justify-content: center;
    }

    .title{
      font-size: 1.2rem;
      font-weight: 420;
      margin: 12px 0;
      padding: 0 10px;
    }

    .patient{
      padding: 5px 10px;
      cursor: pointer;
      white-space: nowrap;
      transition: all 0.25s;
      position: relative;

      &:hover{
        color: $main-color;
      }

      .dot{
        width: 10px;
        height: 10px;
        border-radius: 50%;
        margin-right: 12px;
        background: $light-text-color;
        display: inline-block;
      }

      .loading{
        width: 20px;
        height: 20px;
        position: absolute;
        left: 5px;
        top: 3px;

        svg{
          width: 100%;

          path{
            stroke: $main-color;
          }
        }
      }

      &.selected{
        font-weight: 420;
      }
    }
  }

  .charts{
    overflow: scroll;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 24px;
    border-radius: 10px;
    border: 1px solid #F0F0F0;
    width: 85%;
    padding: 10px;
    margin-left: 24px;

    &.empty{
      display: flex;
      align-items: center;
      justify-content: center;
      align-self: stretch;

      p{
        font-size: 1.2rem;
        font-weight: 420;
      }
    }
  }
}
</style>
